import React from "react";
import { useStaticQuery, graphql } from "gatsby";

// Components
import SecondaryLanding from "../../../components/layouts/secondary-landing";
import SEO from "../../../components/seo/seo";
import HeroChevron from "../../../components/hero/hero-chevron";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import TwoColumn from "../../../components/two-column/two-column";
import MktoForm from "../../../components/mkto-form/mkto-form";
import { StaticImage } from "gatsby-plugin-image";
import Button from "../../../components/custom-widgets/button";

// Helper Functions
import showMktoForm from "../../../helpers/showMktoForm";
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";

const OnlineBanking = () => {
  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: { eq: "hero/business-banking/online-banking/hero-online-business-banking-04132023-XXL.jpg" }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: { eq: "hero/business-banking/online-banking/hero-online-business-banking-04132023-XL.jpg" }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: { eq: "hero/business-banking/online-banking/hero-online-business-banking-04132023-LG.jpg" }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: { eq: "hero/business-banking/online-banking/hero-online-business-banking-04132023-MD.jpg" }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: { eq: "hero/business-banking/online-banking/hero-online-business-banking-04132023-SM.jpg" }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: { eq: "hero/business-banking/online-banking/hero-online-business-banking-04132023-XS.jpg" }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: { eq: "hero/business-banking/online-banking/hero-online-business-banking-04132023-XXS.jpg" }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const heroChevronData = {
    id: "business-credit-solutions-hero",
    ...getHeroImgVariables(imgData),
    altText: "Businesswoman working on laptop at a table",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Treasury Management Made Simple"
          }
        },
        {
          id: 2,
          button: {
            id: "hero-cta-btn-1",
            class: "btn-white",
            text: "Enroll Now",
            externalLink: true,
            target: "_blank",
            url: "https://business.washingtonfederal.com/enrollment/"
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/business-banking",
      title: "Business Banking"
    },
    {
      id: 2,
      active: true,
      title: "Online Business Banking"
    }
  ];

  const SEOData = {
    title: "Online Business Banking",
    meta: [
      {
        name: "title",
        property: "og:title",
        content: "Online Business Banking"
      },
      {
        name: "description",
        property: "og:description",
        content:
          "Have a business account with WaFd Bank? Sign up here to get your credentials for your online business bank account with WaFd Bank (formally Washington Federal)."
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/business-banking/online-banking"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-online-businesss-banking-04132023.jpg"
      }
    ]
  };

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <MktoForm />
      <section id="business-credit-intro-section" className="container">
        <h1>Access Your Business Accounts Online with Our Easy to Use Treasury Products</h1>
        <div className="row mt-5">
          <div className="col-md-6">
            <h3 className="text-success">
              WaFd Bank has two services to help you manage your business needs, WAFD Treasury Express for small
              business and WAFD Treasury Prime for commercial companies looking for more robust features.
            </h3>
          </div>
          <div className="col-md-6">
            <h4>
              From online and mobile banking to remote deposits, we offer a wealth of Treasury Management services for
              your convenience. We will help you create efficiency - whether that saves time or money.
            </h4>
          </div>
        </div>
      </section>

      {/* Treasury Express CTA  */}
      <section className="border-top border-6 border-success pb-0">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-sm-6 my-2 my-sm-0">
              <StaticImage
                src="../../../images/third-party/treasury-express-logo-color.jpg"
                alt="Screenshot of the Treasury Express online business banking dashboard"
                placeholder="blurred"
                quality="100"
                objectFit="contain"
              />
            </div>
            <div className="col-sm-6 d-flex justify-content-sm-end my-2 my-sm-0">
              <Button
                id="sb-treasury-express-cta-button"
                url="https://business.washingtonfederal.com/enrollment/"
                text="Enroll Now"
                type="anchor"
                externalLink
                showIcon={false}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="container">
        <div className="row">
          <div className="col-md-6">
            <StaticImage
              src="../../../images/third-party/treasury-express-dashboard.png"
              alt="Treasury Express online business banking logo"
              placeholder="blurred"
              quality="100"
              className="shadow"
            />
          </div>
          <div className="col-md-6">
            <h3 className="text-success">
              WAFD Treasury Express has everything you need to manage and view your small business accounts online
            </h3>
            <div className="mt-5">
              <h5>Through our user-friendly, online platform you can:</h5>
              <ul>
                <li>Check account balances and activity</li>
                <li>Access eStatements</li>
                <li>Pay bills with small business bill pay</li>
                <li>Make deposits</li>
                <li>Place stop payments</li>
                <li>Transfer funds between accounts</li>
                <li>View cleared check images</li>
                <li>Create &amp; manage multiple sub-users to give employees limited access to your accounts</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* Treasury Prime CTA */}
      <section className="border-top border-6 border-success pb-0">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-sm-6 my-2 my-sm-0">
              <StaticImage
                src="../../../images/third-party/treasury-prime-logo-color.jpg"
                alt="Treasury Prime online business banking logo"
                placeholder="blurred"
                quality="100"
                objectFit="contain"
              />
            </div>
            <div className="col-sm-6 d-flex justify-content-sm-end my-2 my-sm-0">
              <button
                id="sb-treasury-prime-cta-button"
                className="btn btn-primary"
                type="button"
                onClick={() => showMktoForm(1172)}
              >
                Request Access
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className="container">
        <div className="row">
          <div className="col-md-6">
            <StaticImage
              src="../../../images/third-party/treasury-prime-dashboard.png"
              alt="Screenshot of the Treasury Prime online business banking dashboard"
              placeholder="blurred"
              quality="100"
              className="shadow"
            />
          </div>
          <div className="col-md-6">
            <h3 className="text-success">
              Looking for a more robust commercial banking solution? Try WAFD Treasury Prime
            </h3>
            <div className="mt-5">
              <h5>Experience the difference with WAFD Treasury Prime:</h5>
              <ul>
                <li>Self-guided mapping tools remove the technical support from importing files.</li>
                <li>Flexible reporting allows you to export the reports you need and in the format you need.</li>
                <li>
                  Audit reporting tools are available to review and download on demand or regularly to help you mitigate
                  risk.
                </li>
                <li>
                  A seamless integrated solution to provide you the convenience of information at your fingertips.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-sm-4">
            <h5>Display</h5>
            <p>
              Dynamic online functionality - Account balances, stop payments, check images, user administration and both
              previous and current day transaction activity.
            </p>
          </div>
          <div className="col-sm-4">
            <h5>Transact</h5>
            <p>
              Send payments using ACH, Wire, Account Transfers, Check Printing, and Bill Pay. Our consolidated payments
              feature allows you to send all your payments in one convenient file.
            </p>
          </div>
          <div className="col-sm-4">
            <h5>Deliver</h5>
            <p>
              Sending information can be as easy or complex as you need it to be. Send files using FTP, SFTP, or WAFD
              Desktop Delivery. Send reports and account history through scheduled delivery every night right to your
              email.
            </p>
          </div>
        </div>
      </section>
      {/* WAFD Client Connect CTA */}
      <section className="border-top border-6 border-success pb-0">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-sm-6 my-2 my-sm-0">
              <StaticImage
                src="../../../images/third-party/wafd-client-connect-logo.jpg"
                alt="WAFD Client Connect logo"
                placeholder="blurred"
                quality="100"
                objectFit="contain"
              />
            </div>
            <div className="col-sm-6 d-flex justify-content-sm-end my-2 my-sm-0">
              <a
                href="https://online.wafdbank.com/link/register?application=ncino"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-primary"
                id="client-connect-register"
              >
                Register
              </a>
            </div>
          </div>
        </div>
      </section>
      <section id="client-connect-section" className="container">
        <div className="row">
          <div className="col-md-6">
            <StaticImage
              src="../../../images/third-party/client-connect-dashboard.png"
              alt="WAFD Client Connect dashboard"
              placeholder="blurred"
              quality="100"
              className="shadow"
            />
          </div>
          <div className="col-md-6">
            <h3 className="text-success">
              WAFD Client Connect is the gateway to securely send documents to your banker and apply for new commercial
              lending products as an existing client.
            </h3>
            <div className="mt-5">
              <ul>
                <li>A new, easy way to do business with WAFD and your banker</li>
                <li>Uses existing logins so you don't have to use multiple usernames and passwords</li>
                <li>Simple navigation and tools that will guide you along the way</li>
                <li>Safe and secure file transfer</li>
                <li>Expand your business opportunities with us</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </SecondaryLanding>
  );
};

export default OnlineBanking;
